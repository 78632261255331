import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

type SiteData = {
  siteMetadata: { siteTitle: string; description: string; author: string };
};

interface Props {
  pageDescription?: string;
  pageTitle: string;
}

const Seo = ({ pageDescription, pageTitle }: Props) => {
  const { site }: { site: SiteData } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            description
            author
          }
        }
      }
    `
  );

  const metaDescription =
    pageDescription ||
    site.siteMetadata.description ||
    "Potential Cinema Platform";

  return (
    <Helmet
      htmlAttributes={{ lang: "ru" }}
      title={pageTitle}
      titleTemplate={`%s | ${site.siteMetadata.siteTitle}`}
    >
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={site.siteMetadata.siteTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content={"website"} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={site.siteMetadata.siteTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="cmsmagazine" content="77089f8658a00b07e9ed8d2a03ac2540" />
      <meta name="theme-color" content="#F06564" />
      <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      <meta name="yandex-verification" content="1e4f1c0078b17537" />
    </Helmet>
  );
};

export default Seo;
